// Extra small screen / phone
@screen-extra-xs:                  359px;
//** Deprecated `@screen-xs` as of v3.0.1
@screen-xs:                  480px;
//** Deprecated `@screen-xs-min` as of v3.2.0
@screen-xs-min:              @screen-xs;
//** Deprecated `@screen-phone` as of v3.0.1
@screen-phone:               @screen-xs-min;

// Small screen / tablet
//** Deprecated `@screen-sm` as of v3.0.1
@screen-sm:                  768px;
@screen-sm-min:              @screen-sm;
//** Deprecated `@screen-tablet` as of v3.0.1
@screen-tablet:              @screen-sm-min;

// Medium screen / desktop
//** Deprecated `@screen-md` as of v3.0.1
@screen-md:                  992px;
@screen-md-min:              @screen-md;
//** Deprecated `@screen-desktop` as of v3.0.1
@screen-desktop:             @screen-md-min;

// Large screen / wide desktop
//** Deprecated `@screen-lg` as of v3.0.1
@screen-lg:                  1200px;
@screen-lg-min:              @screen-lg;
//** Deprecated `@screen-lg-desktop` as of v3.0.1
@screen-lg-desktop:          @screen-lg-min;

// So media queries don't overlap when required, provide a maximum
@screen-xs-max:              (@screen-sm-min - 1);
@screen-sm-max:              (@screen-md-min - 1);
@screen-md-max:              (@screen-lg-min - 1);

@font_roboto: 'Roboto Slab', serif;
@roboto_regular: 400;

@font_monstserrat: 'Montserrat', sans-serif;
@montserrat_light: 300;
@montserrat_regular: 400;
@montserrat_medium: 500;
@montserrat_bold:700;
@text_color1:#94877a;

.full-screen {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
#footer{background-color: #231f20;padding-top:15px;padding-bottom: 15px;font-family: @font_monstserrat;font-weight: @montserrat_light;font-size: 21px;color:#FFFFFF; /* position: absolute;bottom: 0;*/}
#footer a{color:#FFFFFF;text-decoration: none;}
#footer a:hover{text-decoration:underline;}
#footer .rrss{text-align: right;}

.carousel-indicators li {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 1px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #000\9;
    background-color: rgba(0,0,0,0);
    border: 1px solid #fff;
    border-radius: 10px;
    margin-right: 5px;
}
.carousel-control-prev,.carousel-control-next{display: none;}
.carousel-caption {
    top: 0;
    bottom: auto;
    padding-top: 200px;
    text-align: left;
    left: 0%;
    right: 0%;
    
}

.carousel-caption .title{font-family: @font_roboto;font-weight: @roboto_regular;font-size: 115px;color:#FFFFFF;line-height: 111px;}
.carousel-caption .subtitle{font-family: @font_monstserrat;font-weight: @montserrat_light;font-size: 35px;line-height: 41px;color:#FFFFFF;padding-top: 20px; }	
.carousel-indicators{margin-left:50px;justify-content:left;top: 600px;}
#top_menu_home{position: absolute;z-index: 100;}
.logo_top{padding-top: 70px;}
.menu{padding-top: 40px;text-align: right;}

ul#languages{margin-bottom: 7px;padding-left: 0px;}
ul#languages li{list-style: none;display: inline-block;margin-right:2px;}
ul#languages li:last-child{margin-right:0px;}
ul#languages li a{font-family: @font_monstserrat;color:#FFFFFF;font-size: 12px;text-decoration: none;font-weight: @montserrat_light;}
.content_nohome ul#languages li a{color:#000000 !important;}
ul#languages li.actual a{font-weight: @montserrat_medium;}
ul#languages li a:hover{text-decoration:none;}

.title_page{font-family: @font_roboto;font-weight: @roboto_regular;font-size: 61px;line-height: 54px;color:#214230;}
.text_montserrat_light_21{font-family: @font_monstserrat;font-weight: @montserrat_light;font-size: 21px;line-height: 20px;}
.text_montserrat_light_28{font-family: @font_monstserrat;font-weight: @montserrat_light;font-size: 28px;line-height: 28px;}
.text_montserrat_light_21 a,.text_montserrat_light_21 a:hover{color:@text_color1;text-decoration: underline;}
.text_color1{color:@text_color1;}
.content_page{padding-top:200px;}
.text_align_right{text-align: right !important;}
.text_align_left{text-align: left !important;}
.padding_bottom_30{padding-bottom: 30px;}
.item_staff{padding-bottom: 45px;}

.imagen_staff img{max-width:80%;height:auto;-webkit-filter: grayscale(1);
    filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'gs\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#gs");
    filter: gray;
    filter: grayscale(100%);
    }


    
.imagen_staff {margin-bottom: 15px;text-align: center;}
.nombre_staff{color:#94877a;font-family: @font_monstserrat;font-weight: @montserrat_bold;font-size: 14px;text-align: center;}
.cargo_staff{color:#94877a;font-family: @font_monstserrat;font-weight: @montserrat_light;font-size: 11px;text-align: center;}
.img-circle {border-radius: 50%;}
.title_staff{font-family: @font_roboto;font-weight: @roboto_regular;font-size:38px;color:#94877a;padding-bottom: 50px;}
/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */
#sidebarCollapse:hover{cursor:pointer;}
#sidebar {
    width: 350px;
    position: fixed;
    top: 0;
    right: -350px;
    height: 100vh;
    z-index: 999;
    background: #FFFFFF;
    color: #94877a;
    transition: all 0.3s;
    overflow-y: scroll;
   /* -webkit-box-shadow: -15px 0 0 0 rgba(0,0,0,0.6);
box-shadow: -15px 0 0 0 rgba(0,0,0,0.6);*/
}

#sidebar.active {
    right: 0;
}

#dismiss {
    width: 35px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    position: absolute;
    top: 40px;
    right: 40px;
    cursor: pointer;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

/*#dismiss:hover {
    background: #fff;
    color: #7386D5;
}*/

.overlay {
    display: none;
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);
    z-index: 998;
    opacity: 0;
    transition: all 0.5s ease-in-out;
}
.overlay.active {
    display: block;
    opacity: 1;
}

#sidebar .sidebar-header {
    padding: 20px;
    background: #6d7fcc;
}

#sidebar ul.components {
    padding: 140px 0;
    //border-bottom: 1px solid #47748b;
}

#sidebar ul p {
    color: #fff;
    padding: 10px;
}

#sidebar ul li a {
    padding: 10px;
    font-size: 16px;
    display: block;
    color: #94877a;
    font-family: @font_monstserrat;
    font-weight: @montserrat_regular;
    text-transform: uppercase;
    padding-left:40px;
}

#sidebar ul li a:hover {
   text-decoration: underline;
}

#sidebar ul li.active>a,
a[aria-expanded="true"] {
    //color: #fff;
    //background: #6d7fcc;
    text-decoration: underline;
}

a[data-toggle="collapse"] {
    position: relative;
}

.dropdown-toggle::after {
    display: block;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
}

ul ul a {
    font-size: 0.9em !important;
    padding-left: 30px !important;
    background: #6d7fcc;
}

ul.CTAs {
    padding: 20px;
}

ul.CTAs a {
    text-align: center;
    font-size: 0.9em !important;
    display: block;
    border-radius: 5px;
    margin-bottom: 5px;
}

a.download {
    background: #fff;
    color: #7386D5;
}

a.article,
a.article:hover {
    background: #6d7fcc !important;
    color: #fff !important;
}

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */
#content {
    width: 100%;
    min-height: 100vh;
    transition: all 0.3s;
    position: absolute;
    top: 0;
    right: 0;
}
@media (min-width: 908px){
	.title_page br{display:none}
}
@media (max-width: 991px){
	.text_montserrat_light_28{font-size: 21px}
	.carousel-caption {padding-left: 15px;padding-top: 160px;}
	.carousel-indicators{margin-left:15px;}
	.carousel-caption .title br{display:block;}
	.carousel-caption .title{font-size: 61px;line-height: 57px;}
	.carousel-caption .subtitle{font-size: 26px;line-height: 30px;}
	.carousel-indicators{top: 580px;}
}
@media (min-width: 992px)
{
	.carousel-caption .title br{display:none;}
	.carousel-caption {padding-left: 80px;}
	#footer .rrss{padding-right: 50px;}
	#footer .row div[class="col-6"]:first-child{padding-left:80px;}
	.carousel-indicators{margin-left:80px;}
	.logo_top{padding-left:80px;}
	.menu{padding-right: 50px;}
	.content_page{padding-left:80px;}
	.padding_left_40{padding-left:40px;}
	
}

@media (max-width: 650px)
{
	.title_page{font-size: 40px;line-height: 40px}
}
@media (min-width: 651px) and (max-width:1154px)
{
	.title_page{font-size: 43px;line-height: 47px}
}
@media (min-width:1200px)
{
	.title_page{font-size: 58px;line-height: 62px;}
}

@media (max-width: 575px) 
{
	.title_page br{display:none}
	.title_page {text-align: left !important;margin-bottom: 15px;}
}

@media (max-width: 767px)
{
	.title_page2{text-align: left !important;margin-bottom: 15px !important;}
}

@media (min-width: 576px)  and (max-width: 907px)
{
	.title_page br{display:block}
}

@media (min-width:1155px) and (max-width:1474px)
{
	.title_page br{display:block}
}

@media (max-width:430px)
{
	.title_page br{display:block}
}
.always_display_br br{display:block !important;}

@media (max-width: 767px)
{
.always_display_br br{display: none !important;}	
.oficinas_content{padding-bottom:50px;}
}

@media (min-width: 768px){
	.oficinas_content{padding-top:100px;}
}

.form_container input[type="text"], .form_container input[type="email"],.form_container textarea {
    float: left;
    width: 100%;
    margin-top: 15px;
    font-family: @font_monstserrat;
    font-display: fallback;
    font-weight: @montserrat_bold;
    text-align: left;
    font-size: 14px;
    color: #94877a;
    height: 30px;
    background-color: #fff;
    border-top: 0px solid #94877a;
    border-bottom: 1px solid #94877a;
    border-right: 0px solid #94877a;
    border-left: 0px solid #94877a;
   
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #94877a;
   opacity: 1 !important;
}
::-moz-placeholder { /* Firefox 19+ */
  color: #94877a;
   opacity: 1 !important;
}
:-ms-input-placeholder { /* IE 10+ */
  color: #94877a;
   opacity: 1 !important;
}
:-moz-placeholder { /* Firefox 18- */
  color: #94877a;
   opacity: 1 !important;
}

.form_container textarea{
	border: 1px solid #94877a;
	height: auto;
	margin-top: 0px;
	}
.form_container  label{
	float: left;
    width: 100%;
    margin-top: 15px;
    font-family: @font_monstserrat;
    font-display: fallback;
    font-weight: @montserrat_bold;
    text-align: left;
    font-size: 14px;
    color: #94877a;
    }
    .form_container input[type="checkbox"]{
    	border: 1px solid #94877a;
    }
    .checkboxes_form_content{padding-top:20px;}
    .wpcf7-list-item-label{font-family: @font_monstserrat!important;font-weight: @montserrat_light!important;font-size:13px!important;color:#94877a!important;} 
    .form_container input[type="submit"]{font-family: @font_monstserrat;font-weight: @montserrat_bold;font-size: 15px;margin-top:20px;border:1px solid #94877a;background-color: #FFFFFF;color:#94877a;padding:5px 15px;}
    .city{font-family: @font_monstserrat;font-weight: @montserrat_bold;font-size: 15px;color:#94877a;text-transform: uppercase;}
    .address{font-family: @font_monstserrat;font-weight: @montserrat_light;font-size: 13px;color:#94877a;text-transform: uppercase;}